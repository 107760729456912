import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(email, password) {
	return axios.post(`${Constants.baseUrl}/auth`, { email, password });
};

export function forgot(data) {
	return axios.post(`${Constants.baseUrl}/forgot_password`, data);
};

export function reset(data) {
	return axios.post(`${Constants.baseUrl}/reset_password`, data);
};
