export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Clientes",
				root: true,
				icon: "fas fa-users",
				page: "cliente",
				bullet: "dot"
			},
			{
				title: "Cadastros",
				root: true,
				icon: "fas fa-list",
				bullet: "dot",
				submenu: [
					{
						title: "Profissionais",
						bullet: "dot",
						page: "profissional"
					},
					{
						title: "Produtos",
						bullet: "dot",
						page: "produto"
					},
					{
						title: "Erros e Soluções",
						bullet: "dot",
						page: "erro-solucao"
					},
					{
						title: "Serviços",
						bullet: "dot",
						page: "servico"
					},
					{
						title: "News",
						bullet: "dot",
						page: "conteudo"
					},
					{
						title: "Banners",
						bullet: "dot",
						page: "banner"
					},
					{
						title: "Categorias",
						bullet: "dot",
						page: "categoria"
					}
				]
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Financeiro",
						bullet: "dot",
						page: "relatorio/relatorio01"
					}
				]
			},
			{
				title: "Acessos",
				root: true,
				icon: "fas fa-user-shield",
				bullet: "dot",
				submenu: [
					{
						title: "Administradores",
						bullet: "dot",
						page: "admin"
					},
					{
						title: "Grupos de Acesso",
						bullet: "dot",
						page: "grupo"
					}
				]
			}
		]
	}
};
