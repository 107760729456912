import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GrupoPage = lazy(() =>
	import("./grupo/GrupoPage")
);

const ProfissionalPage = lazy(() =>
	import("./profissional/ProfissionalPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const ErroSolucaoPage = lazy(() =>
	import("./erro-solucao/ErroSolucaoPage")
);

const ServicoPage = lazy(() =>
	import("./servico/ServicoPage")
);

const ConteudoPage = lazy(() =>
	import("./conteudo/ConteudoPage")
);

const BannerPage = lazy(() =>
	import("./banner/BannerPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/categoria" component={CategoriaPage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/grupo" component={GrupoPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/profissional" component={ProfissionalPage} />
				<Route path="/produto" component={ProdutoPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/erro-solucao" component={ErroSolucaoPage} />
				<Route path="/servico" component={ServicoPage} />
				<Route path="/conteudo" component={ConteudoPage} />
				<Route path="/banner" component={BannerPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
